<template>
  <div class="topic" v-if="!initLoading">
    <div
      style="padding: 50px 0; text-align: center; color: #999"
      v-if="(topicList.questions_list || []).length === 0"
    >
      {{ emptyText || "暂无题单" }}
    </div>

    <template v-else>
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        v-if="noTime && duration == 0"
      >
        <el-tab-pane label="题目详情" name="first"></el-tab-pane>
        <el-tab-pane
          label="我的提交"
          v-if="topicList.questions_notype_list[topicIndex].type == 1"
          name="second"
        >
          <div
            style="padding: 1rem; display: flex; justify-content: space-between"
            v-loading="logLoading"
          >
            <div style="width: 70%">
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 18px;
                  font-weight: bold;
                "
              >
                <span style="margin-right: 6px">第{{ topicIndex + 1 }}题</span>
                <div
                  class="description"
                  v-code-html="
                    this.topicList.questions_notype_list[this.topicIndex].title
                  "
                ></div>
              </div>
              <el-divider></el-divider>
              <template>
                <div
                  class="yuan_code"
                  style="display: flex; justify-content: space-between"
                >
                  <div>源代码</div>
                  <div>编程语言：{{ record.lang }}</div>
                </div>
                <CodeEditor
                  class="CodeEditor"
                  ref="CodeEditor"
                  readOnly
                  v-model="record.myAnswer2"
                ></CodeEditor>
              </template>
              <div class="result" v-if="(record.remark.use_cases || []).length">
                <el-divider></el-divider>
                <div class="run-result">
                  <div>程序运行结果</div>
                  <div
                    class="button-primary"
                    :style="{ marginLeft: '1rem', padding: '4px 20px' }"
                    @click="download()"
                  >
                    下载用例 &nbsp; <i class="el-icon-download"></i>
                  </div>
                </div>
                <div class="result-bc">
                  <div
                    class="result-item"
                    v-for="(v, i) in record.remark.use_cases"
                    :key="i"
                    :style="{ backgroundColor: v.color }"
                    @click="download(v)"
                  >
                    <div class="number">#{{ i + 1 }}</div>
                    <div class="name">{{ v.title }}</div>
                    <div class="mem">{{ v.time }}/{{ v.mem }}</div>
                  </div>
                </div>
              </div>
            </div>

            <el-card style="margin-top: 20px; width: 28%">
              <div class="topic_info">
                <el-form ref="form" :model="form" label-width="100px">
                  <!-- <el-form-item label="题目编号：">
                <div class="topic_title">{{detailList.question.id}}</div>
              </el-form-item>
              <el-form-item label="所属题目：">
                <div class="topic_title" v-html="detailList.question.title"></div>
              </el-form-item> -->
                  <el-form-item label="答题结果：">
                    <span :style="{ color: record.remark.color || '#000' }">{{
                      record.remark.label
                    }}</span>
                  </el-form-item>

                  <el-form-item label="提交时间：">
                    <span>{{ record._create_time }}</span>
                  </el-form-item>
                </el-form>
              </div>
              <el-collapse accordion value="1">
                <el-collapse-item name="1">
                  <template slot="title"> 我的提交记录 </template>
                  <el-link
                    :disabled="item.id == record.log_id"
                    v-for="(item, index) in recordList"
                    :key="index"
                    @click="goRecord(item.id, record.id)"
                  >
                    <span>{{ item.grade }}分 — </span
                    ><span>提交于：{{ item._create_time }}</span>
                  </el-link>
                </el-collapse-item>
              </el-collapse>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane label="问题讨论" name="third">
          <div
            style="
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: bold;
              margin-left: 20px;
            "
          >
            <span style="margin-right: 6px">第{{ topicIndex + 1 }}题</span>
            <div
              class="description"
              v-code-html="
                this.topicList.questions_notype_list[this.topicIndex].title
              "
            ></div>
          </div>
          <comment
            ref="comment"
            v-if="activeName == 'third'"
            :topicId="topicList.questions_notype_list[this.topicIndex].qid"
          ></comment>
        </el-tab-pane>
        <el-tab-pane label="题目题解" name="fourth">
          <div
            style="
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: bold;
              margin-left: 20px;
            "
          >
            <span style="margin-right: 6px">第{{ topicIndex + 1 }}题</span>
            <div
              class="description"
              v-code-html="
                this.topicList.questions_notype_list[this.topicIndex].title
              "
            ></div>
          </div>
          <analysis
            ref="analysis"
            v-if="activeName == 'fourth'"
            :topicId="topicList.questions_notype_list[this.topicIndex].qid"
          ></analysis>
        </el-tab-pane>
      </el-tabs>
      <!-- 选择题 -->
      <template
        v-if="
          topicList.questions_notype_list[topicIndex].type == 2 &&
          (topicList.questions_notype_list[topicIndex].is_select == 3 ||
            topicList.questions_notype_list[topicIndex].is_select == 1) &&
          activeName == 'first'
        "
      >
        <DXT
          v-if="topicList.questions_notype_list[topicIndex].is_select == 1"
          v-model="
            answersObj[topicList.questions_notype_list[topicIndex].id].answer
          "
          :stagingAnswer2="
            stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer
          "
          :optionsData="
            optionsData(topicList.questions_notype_list[topicIndex])
          "
          :status="status"
          :key="topicList.questions_notype_list[topicIndex].id"
          :discussShow="discussShow"
          :show_answer="show_answer"
          @change="
            handleEmit(
              'change',
              topicList.questions_notype_list[topicIndex].id,
              ...arguments
            )
          "
        ></DXT>
        <DUOXT
          v-if="topicList.questions_notype_list[topicIndex].is_select == 3"
          v-model="
            answersObj[topicList.questions_notype_list[topicIndex].id].answer
          "
          :stagingAnswer2="
            stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer
          "
          :optionsData="
            optionsData(topicList.questions_notype_list[topicIndex])
          "
          :status="status"
          :key="topicList.questions_notype_list[topicIndex].id"
          :discussShow="discussShow"
          :show_answer="show_answer"
          @change="
            handleEmit(
              'change',
              topicList.questions_notype_list[topicIndex].id,
              ...arguments
            )
          "
        ></DUOXT>
        <el-divider></el-divider>
      </template>
      <!-- 填空题 -->
      <template
        v-if="
          topicList.questions_notype_list[topicIndex].type == 2 &&
          topicList.questions_notype_list[topicIndex].is_select == 0 &&
          activeName == 'first'
        "
      >
        <TKT
          :optionsData="
            optionsData(topicList.questions_notype_list[topicIndex])
          "
          v-model="
            answersObj[topicList.questions_notype_list[topicIndex].id].answer
          "
          :status="status"
          :key="topicList.questions_notype_list[topicIndex].id"
          :stagingAnswer2="
            stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer
          "
          :discussShow="discussShow"
          :show_answer="show_answer"
          @change="
            handleEmit(
              'change',
              topicList.questions_notype_list[topicIndex].id,
              ...arguments
            )
          "
        ></TKT>
        <el-divider></el-divider>
      </template>
      <!-- 判断题 -->
      <template
        v-if="
          topicList.questions_notype_list[topicIndex].type == 2 &&
          topicList.questions_notype_list[topicIndex].is_select == 2 &&
          activeName == 'first'
        "
      >
        <PDT
          :optionsData="
            optionsData(topicList.questions_notype_list[topicIndex])
          "
          v-model="
            answersObj[topicList.questions_notype_list[topicIndex].id].answer
          "
          :stagingAnswer2="
            stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer
          "
          :status="status"
          :key="topicList.questions_notype_list[topicIndex].id"
          :discussShow="discussShow"
          :show_answer="show_answer"
          @change="
            handleEmit(
              'change',
              topicList.questions_notype_list[topicIndex].id,
              ...arguments
            )
          "
        ></PDT>
        <el-divider></el-divider>
      </template>
      <!-- 综合题 -->
      <template
        v-if="
          topicList.questions_notype_list[topicIndex].type == 3 &&
          activeName == 'first'
        "
      >
        <div class="synthesize" style="margin-left: 20px">
          <span
            v-html="topicList.questions_notype_list[topicIndex].title"
          ></span>
          <span
            >（{{ topicList.questions_notype_list[topicIndex].grade }}分）</span
          >
        </div>
        <div
          v-html="topicList.questions_notype_list[topicIndex].description"
          style="margin-bottom: 1rem; margin-left: 20px"
        ></div>
        <div
          v-for="(a, b) in topicList.questions_notype_list[topicIndex]._list"
          :key="b"
        >
          <DXT
            v-if="a.is_select == 1"
            v-model="answersObj[a.id].answer"
            :optionsData="optionsData(a)"
            :stagingAnswerArr="Comprequestions[b]?.last_answer"
            :status="status"
            :key="a.id"
            :discussShow="discussShow"
            :show_answer="show_answer"
            @change="handleEmit('change', a.id, ...arguments)"
          ></DXT>
          <DUOXT
            v-if="a.is_select == 3"
            v-model="answersObj[a.id].answer"
            :optionsData="optionsData(a)"
            :stagingAnswerArr="Comprequestions[b]?.last_answer"
            :status="status"
            :key="a.id"
            :discussShow="discussShow"
            :show_answer="show_answer"
            @change="handleEmit('change', a.id, ...arguments)"
          ></DUOXT>
          <TKT
            :optionsData="optionsData(a)"
            v-model="answersObj[a.id].answer"
            :stagingAnswerArr="Comprequestions[b]?.last_answer"
            v-if="a.is_select == 0"
            :status="status"
            :key="a.id"
            :discussShow="discussShow"
            :show_answer="show_answer"
            @change="handleEmit('change', a.id, ...arguments)"
          ></TKT>
          <PDT
            :optionsData="optionsData(a)"
            v-model="answersObj[a.id].answer"
            :stagingAnswerArr="Comprequestions[b]?.last_answer"
            v-if="a.is_select == 2"
            :status="status"
            :key="a.id"
            :discussShow="discussShow"
            :show_answer="show_answer"
            @change="handleEmit('change', a.id, ...arguments)"
          ></PDT>
          <el-divider></el-divider>
        </div>
      </template>
      <!-- 编程题 -->
      <template
        v-if="
          topicList.questions_notype_list[topicIndex].type == 1 &&
          activeName == 'first'
        "
      >
        <!-- <BCT :optionsData="optionsData(v)"
               v-for="(v,i) in item._list"
               :key="i"
               v-model="answersObj[v.id]"
               :status="status"
               :discussShow="discussShow"
               :show_answer="show_answer"
               @change="handleEmit('change' , v.id , ...arguments)"
          ></BCT> -->
        <BCT
          ref="BCTa"
          :optionsData="
            optionsData(topicList.questions_notype_list[topicIndex])
          "
          v-model="answersObj[topicList.questions_notype_list[topicIndex].id]"
          :status="status"
          :discussShow="discussShow"
          :show_answer="show_answer"
          :lastanswer="topicList.questions_notype_list[topicIndex].last_answer"
          :stagingAnswer2="
            stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer
          "
          @change="
            handleEmit(
              'change',
              topicList.questions_notype_list[topicIndex].id,
              ...arguments
            )
          "
        ></BCT>
      </template>

      <div
        style="display: flex; justify-content: space-between; margin-left: 20px"
      >
        <el-popover placement="top" width="330" trigger="click">
          <el-table
            :show-header="false"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            :cell-style="tableCellstyle"
            height="360"
            :data="topicList.questions_notype_list"
          >
            <el-table-column width="80" property="qid"></el-table-column>
            <el-table-column
              width="250"
              property="title"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-html="scope.row.title"></span>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            slot="reference"
            size="small"
            type="primary"
            @click="checkTopic"
            >题目列表</el-button
          >
        </el-popover>

        <div>
          <el-button @click="changeTopic(-1)" :disabled="topicIndex == 0"
            >上一题</el-button
          >
          <el-button
            >{{ topicIndex + 1 }}/{{
              topicList.questions_notype_list.length
            }}</el-button
          >
          <el-button
            @click="changeTopic(1)"
            :disabled="topicIndex + 1 == topicList.questions_notype_list.length"
            >下一题</el-button
          >
        </div>
        <div v-if="activeName == 'first'">
          <el-button
            size="small"
            v-if="
              duration == 0 &&
              topicList.questions_notype_list[topicIndex].type == 1
            "
            type="primary"
            :loading="buttonLoading"
            @click="testAnswer"
            >提交本题目</el-button
          >
          <el-button
            size="small"
            v-if="(duration == 1 || noTime == 1) && !type"
            type="primary"
            @click="submitAnswer"
            >提交整个题单</el-button
          >
          <el-button
            size="small"
            v-if="type && type == '作业'"
            type="primary"
            @click="stagingAnswer"
            >作业暂存</el-button
          >
          <el-button
            size="small"
            v-if="(duration == 1 || noTime == 1) && type"
            type="primary"
            @click="submitAnswer"
            >提交答案</el-button
          >
        </div>
      </div>
    </template>
    <Payment
      ref="Payment"
      :order_num="pamr.order_num"
      :mobile="pamr.mobile"
      :price="pamr.price"
    ></Payment>
  </div>
</template>

<script>
import { detail, questionAnswer, d_record, refer_record } from "@/api/topic.js";
import CodeEditor from "@/components/CodeEditor/CodeEditor.vue";
import { orderCreate } from "@/api/common.js";
import Payment from "@/components/Payment/Payment.vue";
import comment from "@/views/problemList/student/comment.vue";
import analysis from "@/views/problemList/student/analysis.vue";

import DXT from "@/components/TopicType/DXT.vue";
import DUOXT from "@/components/TopicType/DUOXT.vue";
import PDT from "@/components/TopicType/PDT.vue";
import TKT from "@/components/TopicType/TKT.vue";
import BCT from "@/components/TopicType/BCT.vue";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Object, Array],
      default() {
        return null;
      },
    },
    show_answer: {
      default: () => false,
    },
    deploy: {
      isAnswer: false,
    },
    detailList: {
      default: () => {},
    },
    stagingAnswerList: {
      default: () => {},
    },
    status: {
      default: () => "preview", // preview answer
    },
    discussShow: {
      default: () => false,
    },
    duration: {
      default: () => false,
    },
    emptyText: {
      default: () => "暂无题单", // preview answer
    },
    logId: {
      default: () => 0,
    },
    noTime: {
      default: () => 0,
    },
  },
  components: {
    DXT,
    DUOXT,
    PDT,
    TKT,
    BCT,
    CodeEditor,
    Payment,
    comment,
    analysis,
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        let { obj, list, is_equal } = this.initAnswer(null, v);
        console.log(obj, "obj2222");
        if (
          is_equal === false &&
          this.$tool.obj_equal(list, this.answers) === false
        ) {
          this.initLoading = true;
          this.answers = list;
          this.answersObj = obj;
          console.log(this.answersObj, "1111");
          this.$nextTick(() => {
            this.initLoading = false;
          });
        }
      },
    },
    detailList: {
      // immediate: true,
      deep: true,
      handler(v) {
        console.log(v, "detailListdetailListdetailList");
        // console.log(this.stagingAnswerList.stagingAnswerList, "detailListdetailListdetailList");
        // if(v.questions_notype_list[this.topicIndex].type==3 && v.questions_notype_list.length==1){
        if (v.questions_notype_list[this.topicIndex].type == 3) {
          console.log("当前题是综合题");
          if (
            this.stagingAnswerList &&
            this.stagingAnswerList.stagingAnswerList
          ) {
            this.Comprequestions =
              this.stagingAnswerList.stagingAnswerList.flat();
          }

          console.log(this.Comprequestions, "当前题综合题答案数组=========");
        }
        this.topicList = v || {};
        this.initLoading = true;
        let { obj, list, is_equal } = this.initAnswer(
          this.topicList.questions_list || []
        );
        if (is_equal === false) {
          this.answers = list;
          // console.log(this.answers,"this.answerswacth")
          this.answersObj = obj;
        }
        this.$nextTick(() => {
          this.initLoading = false;
        });
      },
    },
  },
  computed: {
    optionsData() {
      return (v) => {
        // console.log(v,"optionsDataoptionsData")
        return {
          topicData: v,
          isAnswer: this.deploy.isAnswer,
        };
      };
    },
  },
  data() {
    return {
      topicList: {},
      answers: [], //已经做了的题目答案
      answersObj: {},
      answerDataList: [],
      OldAnswers: {},
      initLoading: false,
      topicIndex: 0, //编程题索引
      activeName: "first",
      buttonLoading: false, //按钮加载
      id: 0,
      topiclogId: 0,
      record: { remark: { use_cases: [] } },
      detail: {},
      detailInfo: {
        title: "",
        question: { description: "", use_cases: [], user_use_cases: {} },
      },
      question: {
        user_use_cases: { use_cases: [] },
        question: { description: "" },
      },
      timer: null,
      logLoading: false,
      form: {},
      recordList: [],
      recordCount: 0,
      firstLogid: 0, //第一个答题id
      pamr: {
        order_num: "",
        mobile: "",
        price: 0,
      },
      hasDuration: 0,
      type: "", //进来答题类型
      arr: [],
      stagingAnswer2: "",
      Comprequestions: [], //综合题数据
    };
  },
  created() {
    console.log(this.stagingAnswerList, "暂存的作业~~~~~~~~");
    console.log(this.detailList, "a==============~~");
    console.log(this.topicList, "1111111111");

    // stagingAnswerList?.stagingAnswerList[topicIndex]?.last_answer

    this.hasDuration = this.$route.query.duration;
    this.type = this.$route.query.type;
    console.log(this.type, "type11111");
    this.id = this.$route.query.id;
    // console.log(this.id, "idididid");
    // this.topicList = this.detailList || {};
    // if(this.topicList.length<1){
    this.topicList = this.detailList || {};
    // }

    this.initLoading = true;
    let { obj, list, is_equal } = this.initAnswer();
    if (is_equal === false) {
      this.answers = list;
      this.answersObj = obj;
      console.log(this.answersObj, " this.answersObj!~~~~~~~~this.answersObj");
    }

    this.$nextTick(() => {
      this.initLoading = false;
    });
  },
  mounted() {
    this.initLoading = true;
    let { obj, list, is_equal } = this.initAnswer();
    if (is_equal === false) {
      this.answers = list;
      this.answersObj = obj;
    }

    this.topicList = this.detailList || {};
    this.$nextTick(function () {
      this.initLoading = false;
    });
  },
  beforeDestroy() {
    console.log("销毁前");
    this.stopSetInterval();
  },
  destroyed() {
    console.log("销毁后");
    this.stopSetInterval();
  },
  methods: {
    // 定时判题
    createSetInterval(log_id, id) {
      this.stopSetInterval();
      let _this = this;
      this.timer = setInterval(() => {
        _this.get_d_record(log_id, id);
      }, 1000);
    },
    // 关闭轮询
    stopSetInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    changelastanser(e) {
      console.log(e, "eeeeee452");
      if (e) {
        this.topicList.questions_notype_list[this.topicIndex].last_answer = e;
      }
    },
    // 表格背景色
    tableRowClassName({ row, rowIndex }) {
      // console.log(rowIndex, "rowIndex");
      if (rowIndex === this.topicIndex) {
        return "check-row";
      }
      return "";
    },
    // 表格背景色
    tableCellstyle({ row, rowIndex }) {
      // console.log(rowIndex, "rowIndex");
      if (rowIndex === this.topicIndex) {
        return "background:  #00957E";
      }
      return "";
    },
    // 上下题切换
    changeTopic(e) {
      // console.log(this.answers, "当前题目提交答案");
      this.topicIndex += e;
      console.log(this.topicList, "1111111111");
      console.log(this.topicList.questions_notype_list, "222222222222");
      console.log(
        this.topicList.questions_notype_list[this.topicIndex],
        "33333333333"
      );

      if (this.topicList.questions_notype_list[this.topicIndex].type == 3) {
        console.log("当前题是综合题");
        if (this.stagingAnswerList.stagingAnswerList)
          this.stagingAnswerList.stagingAnswerList.forEach((v) => {
            if (v && v instanceof Array) {
              this.Comprequestions = v;
              // 多个一题多问
              // this.Comprequestions = this.stagingAnswerList.stagingAnswerList[this.topicIndex];
            }
          });
        console.log(this.Comprequestions, "当前题综合题答案数组");
      }
      // console.log(this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid,"this.answers[this.topicIndex]")
      if (this.hasDuration == 0) {
        this.getRecords(
          this.topicList.questions_notype_list[this.topicIndex].qid
        );
      }
      if (this.$refs.BCTa) {
        this.$refs.BCTa.flag = true;
      }
    },
    //点击提交记录
    goRecord(logId, id) {
      // console.log(logId,id,"")
      this.get_d_record(
        logId,
        this.topicList.questions_notype_list[this.topicIndex].qid
      );
    },
    // 提交答案
    submitAnswer() {
      // 单个题目提交
      //  this.answers= this.answers.filter(v=>v.quest_id == this.topicList.questions_notype_list[0]?._list[this.topicIndex].id)
      //  if(!this.answers[0].language){
      //  return this.$message.error('请选择编程语言')
      //  }
      console.log(this.answers, "提交答案");
      this.$emit("submit");
    },
    // 作业暂存
    stagingAnswer() {
      this.$confirm("是否暂存此次作业以便下次继续做?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.topicList.questions_notype_list, "999");
          // 将_list拿出来
          let newArr = [];
          this.topicList.questions_notype_list.forEach((item) => {
            // 如果对象不包含_list属性，则直接添加到arr2
            if (!("_list" in item)) {
              newArr.push(item);
            } else {
              // 如果包含_list属性，则遍历_list并将每个对象添加到arr2
              item._list.forEach((listItem) => {
                newArr.push(listItem);
              });
            }
          });
          console.log(newArr, "newArr");
          this.stagingAnswerList.stagingAnswerList.flat().sort(function (a, b) {
            return a.quest_id - b.quest_id;
          });
          console.log(
            this.stagingAnswerList.stagingAnswerList.flat(),
            "stagingAnswerList9999999999999"
          );
          console.log(this.answers, "answersanswers");
          // return
          // 存在上一次的答案
          let arr = []; //处理排序后的数组
          let arr2 = []; //第二次答之后
          let arr3 = []; //未重新做答就去上一次答案
          let arr4 = []; //arr2和arr3集合
          let sortArr = [];
          if (
            this.stagingAnswerList.stagingAnswerList &&
            this.stagingAnswerList.stagingAnswerList.length > 0
          ) {
            // 综合题 结构不一样
            // if (this.topicList.questions_notype_list[this.topicIndex].type == 3 ) {
            //   console.log('综合体');
            //   this.topicList.questions_notype_list[this.topicIndex]._list.forEach((v) => {
            //     this.stagingAnswerList.stagingAnswerList.forEach((vv) => {
            //       if (v.id == vv.quest_id) {
            //         arr.push(vv);
            //       }
            //     });
            //   });
            // } else {
            //   console.log('不是综合体');

            newArr.forEach((v) => {
              // console.log(v,"newArrnewArrnewArr")
              this.stagingAnswerList.stagingAnswerList.flat().forEach((vv) => {
                // console.log(vv,"this.stagingAnswerList.stagingAnswerList.flat()")
                if (v.id == vv.quest_id) {
                  arr.push({ pid: v.pid, ...vv });
                }
              });
            });
            // }

            console.log(arr, "arrarr");
            console.log(this.answers, "this.answers");
            arr.forEach((v) => {
              // console.log(v,"arr下的vvv")
              this.answers.forEach((vv) => {
                // console.log(vv,"this.answersthis.answers")
                if (v.quest_id == vv.quest_id) {
                  vv.pid = v.pid;
                  if (vv.answer == null || vv.answer == "") {
                    arr3.push(v);
                  } else {
                    arr2.push(vv);
                  }
                }
              });
            });
            console.log(arr2, "arrarr22222");
            console.log(arr3, "arrarr3333");
            arr4 = [...arr2, ...arr3];
            console.log(arr4, "44444");
            // 再次排序
            arr.forEach((v) => {
              // console.log(v,"newArrnewArrnewArr")
              arr4.forEach((vv) => {
                if (v.quest_id == vv.quest_id) {
                  sortArr.push(vv);
                }
              });
            });
            console.log(sortArr, "再一次的排序");
          } else {
            //  this.topicList.questions_notype_list.forEach((v) => {
            newArr.forEach((v) => {
              this.answers.forEach((vv) => {
                if (v.id == vv.quest_id) {
                  arr4.push({ pid: v.pid, ...vv });
                }
              });
            });
            sortArr = arr4;
            console.log(sortArr, "else");
            // arr4 = this.answers;
          }
          // [
          //   {pid:0,a:"111"},
          //   {pid:1,a:"222"},
          //   {pid:2,a:"333"},
          //   {pid:2,a:"444"},
          // ]

          // [
          //     {pid:0,a:"111"},
          //   [
          //     {pid:1,a:"222"}
          //   ],
          //   [
          //     {pid:2,a:"333"},
          //     {pid:2,a:"444"}
          //   ],
          // ]
          // 处理多一题多问
          // let result = [];
          // let lastPid = null;
          // let lastArray = [];

          // for (let i = 0; i < sortArr.length; i++) {
          //   let item = sortArr[i];
          //   if (item.pid !== lastPid) {
          //     if (lastArray.length > 0) {
          //       result.push(lastArray);
          //     }
          //     lastArray = [item];
          //     lastPid = item.pid;
          //   } else {
          //     lastArray.push(item);
          //   }
          // }

          // // 将最后一个数组添加到结果中
          // if (lastArray.length > 0) {
          //   result.push(lastArray);
          // }

          const result = [];
          let currentGroup = null;
          sortArr.forEach(item => {
            if (item.pid &&Number(item.pid>0) ) {
              if (!currentGroup) {
                // 如果当前没有分组，创建一个新的分组数组
                currentGroup = [];
                result.push(currentGroup);
              }
              // 将当前元素添加到分组数组中
              currentGroup.push(item);
            } else {
              // 如果当前元素没有pid，那么它不是分组的一部分
              if (currentGroup) {
                // 如果当前有分组，结束当前分组
                currentGroup = null;
              }
              // 将当前元素添加到结果数组中
              result.push(item);
            }
          });
          console.log(result, "resultresult");
          // result.forEach((v, i) => {
          //   if (result[i][0].pid == 0) {
          //     result[i]=result[i][0]
          //   }
          // });
          console.log(result, "resultresult");

          // return
          let data = {
            other_id: this.id,
            other_type: "other",
            data: result,
            // data: arr4,
          };
          console.log(data, "暂存的答案");
          // return
          this.$http
            .post("/xapi/question.question/setQuestionTemp", data)
            .then((res) => {
              console.log(res, "暂存成功");
              this.$message({
                type: "success",
                message: "暂存成功!",
              });
                setTimeout(()=>{
                  window.close()
              },1500)
            })
            .finally(() => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    flattenArray(arr, result = []) {
      arr.forEach((item) => {
        if (item._list && item._list.length > 0) {
          // 如果存在 _list 属性，则递归调用 flattenArray 函数处理 _list 中的子项
          flattenArray(item._list, result);
          // 删除当前对象的 _list 属性
          delete item._list;
        }
        // 将当前对象添加到结果数组中
        result.push(item);
      });
      return result;
    },
    // 测试提交
    testAnswer() {
      // console.log(this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid, "this.topicList.questions_notype_list[0]?._list[this.topicIndex]");
      // console.log(this.answers, "answers");

      this.answers = this.answers.filter(
        (v) =>
          v.quest_id == this.topicList.questions_notype_list[this.topicIndex].id
      );
      this.answers[0].quest_id =
        this.topicList.questions_notype_list[this.topicIndex].qid;
      console.log(this.answers, "this.answers");
      // return
      this.buttonLoading = true;

      questionAnswer({
        id: this.answers[0].quest_id,
        answers: this.answers,
      })
        .then(({ data }) => {
          console.log(data, "datadata");
          this.topiclogId = data.id;
          this.get_d_record(this.topiclogId, this.answers[0].quest_id);

          this.buttonLoading = false;
          this.$message({ message: "提交成功", type: "success" });
          this.activeName = "second";
          this.getRecords(
            this.topicList.questions_notype_list[this.topicIndex].qid
          );
        })
        .catch();
    },
    //获取答题详情
    get_d_record(log_id, id, callback = null) {
      // return
      // this.logLoading = true;
      d_record({ log_id, id })
        .then((res) => {
          // this.logLoading = false;
          let datas = res.data.info;
          let record = this.question;
          record.log_id = datas.log_id || datas.id;
          record.children = datas._list || this.detailInfo.children || [];
          record._create_time = datas._create_time;
          record.remark = datas.remark;
          record.lang = datas.lang;
          var myAnswer = [];
          if (datas.answers) {
            myAnswer = { ...datas.answers };
          } else if (datas.answer instanceof Array) {
            myAnswer = datas.answer.map((v) => {
              if (datas.type + "" === "3") {
                let ls = v.split("：");
                return (ls.length > 1 ? ls[1] : v).split("、");
              } else {
                return v;
              }
            });
          } else if (datas.answer instanceof Object) {
            myAnswer = [];
          } else {
            myAnswer = datas.answer;
          }
          if (datas.type + "" === "3") {
            record.myAnswer = {};
            for (let i = 0; i < record.children.length; i++) {
              let q = record.children[i];
              record.children[i] = {
                ...q,
                type: record.type,
                myAnswer: (myAnswer[q.id] || {}).answer,
                remark: (myAnswer[q.id] || {}).remark,
              };
              record.myAnswer[q.id] = {
                quest_id: q.id,
                answer: (myAnswer[q.id] || {}).answer,
              };
            }
          } else {
            record.myAnswer = {
              [record.id]: {
                quest_id: record.id,
                answer: myAnswer,
                language: record.language,
              },
            };
          }
          record.my_grade = datas.grade;
          console.log("get_d_record", record);

          record.myAnswer2 =
            record.myAnswer[Object.keys(record.myAnswer)[0]].answer;
          // console.log(Data,"Data");
          // record.myAnswer = 'myAnswer: ' + JSON.stringify(Data);
          this.record = record;

          // console.log(this.record,"this.recordthis.record");
          if (
            this.record &&
            log_id &&
            id &&
            this.record.remark &&
            (this.record.remark || {}).label === "Wait"
          ) {
            console.log("等待中~~~~~~~~~~");
            this.createSetInterval(log_id, id);
          } else {
            // this.getRecords(this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid);
            // console.log("出结果了")
            this.stopSetInterval();

            this.$forceUpdate();
            // this.get_d_record(log_id, id)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取提交记录列表
    getRecords(id) {
      // console.log(id,"ididid")

      // if(id && this.userInfo.id && this.recordList.length === 0){
      // if(id && this.userInfo.id){
      refer_record({
        answer_user_id: this.userInfo.id,
        id: id,
        page_size: 99,
        page: 1,
      }).then((res) => {
        this.recordList = res.data.data;
        // console.log(this.recordList ,"this.recordList ")
        // if(this.recordList.length>0&&this.recordList[0].id){
        // }
        this.firstLogid = this.recordList[0].id;
        console.log(this.firstLogid, "第一个答题记录id");
        this.get_d_record(
          this.firstLogid,
          this.topicList.questions_notype_list[this.topicIndex].qid
        );
        this.getDetail(
          this.firstLogid,
          this.topicList.questions_notype_list[this.topicIndex].qid
        );
        this.recordCount = res.data.total;
      });
      // }
    },
    //测试用例下载
    download(item) {
      item = item || {};
      console.log(item, "item");

      // console.log(this.topicList ,"topicListtopicList")
      // return
      var id = this.detailInfo.question.id;
      if (this.detailInfo.question.is_buy + "" === "1") {
        if (item.name) {
          window.location.href = encodeURI(
            (location.baseURL || "") +
              `/api/v1_0_0.question/cases_down?name=${
                item.name
              }&id=${id}&user_id=${localStorage.getItem(
                "user_id"
              )}&user_token=${encodeURIComponent(
                localStorage.getItem("user_token")
              )}`
          );
        } else {
          window.location.href = encodeURI(
            (location.baseURL || "") +
              `/api/v1_0_0.question/cases_down?id=${id}&user_id=${localStorage.getItem(
                "user_id"
              )}&user_token=${encodeURIComponent(
                localStorage.getItem("user_token")
              )}`
          );
        }
      } else {
        //创建订单
        orderCreate({
          type: 1,
          id: id,
        }).then((res) => {
          this.$refs.Payment.show = true;
          this.pamr = {
            order_num: res.data.order.order_num,
            mobile: res.data.order.mobile,
            price: res.data.order.price,
          };
        });
      }
    },

    getDetail(log_id, id) {
      console.log(log_id, "log_idlog_id");
      // return
      detail({ id })
        .then((res) => {
          console.log(res, "resresres");

          let question = (res.data || {}).question || {};
          let children = (res.data || {}).children || [];
          let options = (res.data || {}).options || [];
          this.question = { ...question, options };
          this.detailInfo = { ...res.data, children, question };
          // this.get_d_record(log_id, id)
        })
        .finally(() => {});
    },
    // tab切换
    handleClick(tab, event) {
      // this.$nextTick(()=>{
      // setTimeout(() => {
      //   this.getRecords(this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid);
      //   }, 500);
      // })
      // console.log(tab.name, event);
      // this.$forceUpdate()
      if (tab.name == "second") {
        console.log("我的提交");
        this.$set(this.record, "myAnswer2");
        this.$set(this.record, "lang");
        this.$nextTick(() => {
          setTimeout(() => {
            this.getRecords(
              this.topicList.questions_notype_list[this.topicIndex].qid
            );
          }, 500);
        });
        console.log(this.firstLogid, "this.firstLogfirstLogid");
        // this.get_d_record(this.firstLogid, this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid)
        // setTimeout(() => {
        //   if(this.firstLogid&&this.firstLogid>0){
        //     this.get_d_record(this.firstLogid, this.topicList.questions_notype_list[0]?._list[this.topicIndex].qid)
        //   }
        //   }, 500);
      }
    },
    // 查看题单题目
    checkTopic() {
      console.log("查看题单题目");
    },
    // 跳转题目
    rowClick(row) {
      const index = this.topicList.questions_notype_list.indexOf(row);
      this.topicIndex = index;
      console.log("当前行的索引为：", index);
    },
    initAnswer(detailList, value) {
      detailList = detailList || (this.detailList || {}).detailList || [];
      // console.log("当前行的索detailList：", detailList);
      value = value || this.value || {};
      if (detailList.length > 0) {
        let { obj, list, is_equal } = this.handleInitMoreValue(value);
        // console.log({obj, list, is_equal} ,'!!!!!!!!!!!');

        return { obj, list, is_equal };
      } else {
        return { obj: {}, list: [], is_equal: false };
      }
    },
    handleEmitChange(e, a, c) {
      // console.log(e,a,c,"当前传递过来的参数")
      let list = [];
      let pitchNums = [];
      // console.log(this.answersObj, "this.answersObj");

      for (const listKey in this.answersObj) {
        // console.log(listKey, "objlistKey~~~~~~");
        var obj = { ...this.answersObj[listKey], quest_id: listKey };
        // console.log(obj, "obj~~~~~~");

        obj.answer =
          obj.answer instanceof Array ? obj.answer.join("、") : obj.answer;
        if (obj.answer || obj.answer === 0) {
          pitchNums.push(listKey + "");
        }
        list.push(obj);
      }
      this.answers = list;
      console.log(this.answers, "this.answersthis.answersthis.answers~");
      // let arr = [];
      // this.topicList.questions_notype_list.forEach((v) => {
      //   this.answers.forEach((vv) => {
      //     if (v.id == vv.quest_id) {
      //       arr.push(vv);
      //     }
      //   });
      // });
      // console.log(arr, "arrarrarr~");

      //  this.$refs.BCTa.setValue(this.topicList.questions_notype_list[this.topicIndex].last_answer)
      this.$emit("change", this.answers);
      this.$emit("setAnswers", this.answers);
      this.$emit("setPitchNums", pitchNums);
    },
    handleInitOneValue(item, is_equal = true, list = []) {
      let obj = null;
      let { quest_id, answer, language } = item || {};
      if (quest_id) {
        let _answer = { quest_id, answer, language };
        let index = list.map((d) => d.quest_id).indexOf(quest_id);
        _answer.answer = answer === undefined ? null : answer;
        if (is_equal) {
          if (
            this.$tool.obj_equal(this.answersObj[quest_id], _answer) === false
          ) {
            is_equal = false;
          }
        }
        if (index === -1) {
          list.push(_answer);
        } else {
          list[index] = _answer;
        }
        obj = _answer;
      }
      return { obj, is_equal, list };
    },
    handleInitMoreValue(v) {
      var is_equal = true,
        obj = { ...this.answersObj },
        list = [];
      var eachFunc = (_list) => {
        for (let k = 0; k < _list.length; k++) {
          let _question1 = _list[k];
          // console.log(_question1, "_question1_question1");

          if ((_question1._list || []).length > 0) {
            eachFunc(_question1._list);
          } else if (
            _question1.id &&
            this.answersObj[_question1.id] === undefined
          ) {
            is_equal = false;
            let _obj = this.handleInitOneValue({
              ..._question1,
              quest_id: _question1.id,
              answer: null,
            });
            // console.log(_obj, "_obj_obj");

            if (_obj.obj) {
              obj[_obj.obj.quest_id] = _obj.obj;
              list = _obj.list;
            }
          }
        }
      };
      for (
        let i = 0;
        i < ((this.detailList || {}).questions_list || []).length;
        i++
      ) {
        eachFunc(this.detailList.questions_list[i]._list);
      }
      if (v instanceof Array) {
        for (let i = 0; i < v.length; i++) {
          let _obj = this.handleInitOneValue(v[i], is_equal, list);
          if (_obj.obj) {
            obj[_obj.obj.quest_id] = _obj.obj;
            is_equal = _obj.is_equal;
            list = _obj.list;
          }
        }
      } else if (v instanceof Object) {
        for (const objKey in v) {
          let _obj = this.handleInitOneValue(v[objKey], is_equal, list);
          if (_obj.obj) {
            obj[_obj.obj.quest_id] = _obj.obj;
            is_equal = _obj.is_equal;
            list = _obj.list;
          }
        }
      }
      return { obj, list, is_equal };
    },
    init(ofd) {
      ofd = ofd || {};
      this.OldAnswers = ofd.OldAnswers || {};
    },
  },
};
</script>

<style>
.el-table .check-row {
  background: #00957e !important;
  color: #ffffff;
}
.el-table__row:not(.el-table__row--hover-row):hover {
  background-color: inherit !important;
}
</style>
<style lang="less" scoped>
@import "../../assets/css/topic.less";
::v-deep {
  .el-collapse-item__content {
    height: 400px;
    overflow-y: scroll;
  }
  .el-form-item__content,
  .el-form-item__label {
    line-height: 30px;
    margin-top: 0.3rem;
  }
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
}
.topic {
  .content {
    min-width: 0px !important;
  }
}
.result {
  padding: 1rem 0rem;
  .run-result {
    display: flex;
    align-items: center;
  }
  .list-item {
    display: flex;
    font-size: 14px;
    color: #2a2a2a;
    align-items: center;
    margin: 1rem 0;
    .item-right {
      display: flex;
      align-items: center;
      .item-result {
        padding: 4px 6px;
        background-color: #00957e;
        color: #fff;
        margin-left: 0.5rem;
      }
    }
  }
}
.yuan_code {
  margin: 1rem 0;
}
.result-bc {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  .result-item {
    cursor: pointer;
    margin: 16px;
    color: #fff;
    width: 90px;
    height: 90px;
    padding: 14px;
    border-radius: 10px;
    .name {
      text-align: center;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 3rem;
    }
    .mem {
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
