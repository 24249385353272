import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"
import NProgress from 'nprogress';
import "nprogress/nprogress.css";
import tool from "@/Utils/tool";

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    // mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        {
            name: 'redirect',
            path: '/redirect',
            meta:{title:'跳转'},
            component: {
                template:'<span></span>',
                mounted() {
                    this.$nextTick(()=>{
                        this.$router.replace(this.$route.query);
                    })
                }
            },
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    }
})
const routeAlls = {};
NProgress.start()
var historys = [];
var isReplace = false;
var currentRoute = {};
router.beforeEach(async (to, from, next) => {
    if(NProgress.status === null) NProgress.start();
    updateTitle(to);
    let isInit = routeAlls[location.$module] !== true;
    if(isInit){
        routeAlls[location.$module] = true
        await import('./routes/'+location.$module).then(({routes})=>{
            routes = routeEach(routes , function(route , parents){
                var isProps = !(route.props === false || (route.meta||{}).props === false) 
			, props = Object.assign(route.props ||{},(route.meta||{}).props === true ? {}: ((route.meta||{}).props||{}));
                if(isProps){
                    for (let i = 0; i < parents.length; i++) {
                        if(parents[i].props === false){
                            isProps = false;
                            props = null;
                            break;
                        }else{
							props = Object.assign(props , parents[i].props || {});
						}
                    }
                }
                if(route.meta)route.meta.props = isProps ? props : false;
                route.props = isProps ? props : false;
				return route;
            });
            for (let i = 0; i < routes.length; i++) {
                router.addRoute(routes[i])
            }
        })
    }
    let crumb = [];
    var params = {...to.meta.props,...to.params};
    for (let i = 0; i < to.matched.length; i++) {
        to.matched[i].props.default = {...params}
    }
    currentRoute = Object.assign({} , to);
    if(isInit){
        await store.dispatch(location.$module+'/getMenuList').finally(()=>{
            crumb = getSubData(to , from)
            store.commit("SET" , {key:'currentCrumbList',value:crumb})
            next(to);
        });
    }else{
        // store.commit("SET" , {key:'menuList',value:store.state[location.$module].menuList})
        crumb = getSubData(to , from);
        store.commit("SET" , {key:'currentCrumbList',value:crumb})
        next();
    }
})
router.afterEach((to) => {
    if(historys.length > 0 && isReplace){
        historys[0] = {...to};
    }else{
        delHistorys(to);
        historys.unshift(to);
    }
    setTimeout(()=>{NProgress.done(true);},150)
});
function routeEach(routes , callback){
    var $routeEach = (routes , callback , parents = [] , level = 1)=>{
        if(callback && callback instanceof Function){
            let $routes = [];
            for (let i = 0; i < routes.length; i++) {
                let $route = {...routes[i]};
                let result = callback($route , parents);
                if(result === false){
                    continue;
                }
                if(result && result instanceof Object){
                    $route = {...result};
                }
                // console.log(" ---- routeEach" , level ,'name:', $route.name,'path:', $route.path);
                if(($route.children || []).length > 0){
                    $route.children = $routeEach($route.children , callback , [$route].concat(parents) , level + 1);
                }
                $routes.push($route);
            }
            routes = [...$routes];
        }
        return routes;
    }
    return $routeEach(routes , callback);
}
/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle(route) {
    if (!route.path.startsWith('/redirect/')) {
        let names = [];
        if(route && route.params.title){
            names.push(route.params.title);
        }else if (route && route.meta && route.meta.title) {
            names.push(route.meta.title);
        }else if (route && route.name) {
            names.push(route.name);
        }
        const appName = process.env.VUE_APP_NAME || 'KenCoding';
        names.push(appName);
        document.title = names.join(' - ');
    }
}
var getTitle = function(route){
    return (route.meta||{}).title === undefined || (route.meta||{}).title === null ? route.name : (route.meta||{}).title;
}
function getSubData(to , from,callback){
    let {path , name} = to || {};
    var crumbs = [] , isLogin = null , isDisabled = null;
    var getParentData = (func , route,sublevel = 1) => {
        var data = [];
        if(route.parent && route.parent.path !== ''){
            data = [...func(func , route.parent , sublevel + 1)];
        }
        if(isLogin === null && ((route.meta||{}).login === true || (route.meta||{}).login === false)){
            isLogin = (route.meta||{}).login;
        }
        if((sublevel === 1 || (route.path && route.path !== '/')) && (route.components || {}).default !== undefined){
            if(getTitle(route))data.push({path:route.path , name:route.name,title:getTitle(route),regex:route.regex})
        }
        return data;
    }
    var getCrumbs = (path , name) => {
        var crumbs = name ? router.getRoutes().filter(d=>d.name && name.indexOf(d.name) > -1) : (path === '/' ? router.getRoutes().filter(d=>d.path === '/') : []);
        let l = crumbs.length;
        if(l === 0 ){
            crumbs = router.getRoutes().filter(d=>d.path !== '/' && d.path !== '/*' && d.regex.test(path));
            if(crumbs.length > 0 ){
                crumbs = getParentData(getParentData , crumbs[0]);
            }
        }else if(l === 1){
            crumbs = getParentData(getParentData , crumbs[l -1]);
        }else{
            var _crumbs = [];
            for (let i = 0; i < l; i++) {
                if(crumbs[i].path && crumbs[i].path !== '/' && (crumbs[i].components || {}).default !== undefined){
                    let $subdata = [];
                    if(crumbs[i].regex.test(path)){
                        $subdata = getParentData(getParentData , crumbs[i]);
                    }else{
                        if(getTitle(crumbs[i]))$subdata = [{path:crumbs[i].path , name:crumbs[i].name,title:getTitle(crumbs[i]),regex:crumbs[i].regex}];
                    }
                    for (let j = 0; j < $subdata.length; j++) {
                        if(_crumbs.filter(d=>d.name===$subdata[j].name).length === 0){
                            _crumbs.push($subdata[j]);
                        }
                    }
                }
            }
            crumbs = [..._crumbs];
        }
        return crumbs;
    }
    if(path !== '/'){
        crumbs = getCrumbs(path , name);
        let menuList = store.state.menuList || [];
        let topCrumbs = crumbs.length > 0 ? menuList.filter(d=>d.path === crumbs[0].path) : [];
        if(topCrumbs.length === 0){
            let firTo = menuList.filter(d=>(path+'/').indexOf(d.path+'/') === 0)[0] || null;
            if(firTo && firTo.path !== path){
                let fircrumbs = getCrumbs(firTo.path);
                if(fircrumbs.length > 0){
                    crumbs = fircrumbs.concat(crumbs.filter(d=>fircrumbs.map(d=>d.title).indexOf(d.title) === -1));
                }
            }
        }
        if(crumbs.filter(d=>d.path === '/').length === 0){
            crumbs = getCrumbs('/').concat(crumbs);
        }
    }else{
        crumbs = getCrumbs('/');
    }
    if(isLogin !== null && store.state.lodinShow !== isLogin){
        store.commit("SET" , {key:'lodinShow',value:isLogin === true && !((store.state.userInfo || {}).id > 0)})
    }
    // if(isDisabled === true){
    //     return MessageBox.alert("您没有权限访问【"+( (to.meta || {}).title || to.name)+"】页面");
    // }
    // console.log("getSubData ------ crumbs" , [...crumbs] , router.getRoutes().filter(d=>d.name.indexOf('teachingQuestionEdit') > -1));
    return [...crumbs];
}

function delHistorys(to){
    let isIn = -1;
    let _historys = [];
    for (let i = 0; i < historys.length; i++) {
        if(isIn === -1 && (historys[i].path === to.path || historys[i].name === to.name)){
            if(!tool.obj_equal(historys[i].query || {} , to.query)){
                isIn = i;
            }
        }
        if(isIn > -1 && isIn > i){
            _historys.push({...historys[i]});
        }
    }
    if(isIn >= 0){
        historys = [..._historys];
    }
    return isIn;
}
export function getRouteTargetUrl(to , path  , isRoute = true, isLogin = null){
    if(isRoute === false || !(to instanceof Object && typeof to.path === 'string')){
        to = getRouteToUrl(to , path , isLogin);
    }
    let routeData = router.resolve(to);
    return (routeData.href || '').substr(0,3) === '/./' ?routeData.href.substr(3) : routeData.href;
}
export function getRouteToUrl(query , path){
    if(!query && !path)return;
    if(!query || !(query instanceof Object)){
        if(query && typeof query === 'string' && !path){
            path = query + "";
        }
        query = {};
    }
    path = path || '';
    query = query || {};
    if(path && tool.isUrl(path)){
        return tool.variableData(path , query);
    }
    var currentPath = currentRoute.path + "" , isLogin = null, routes = [] , Obj = null;
    let paramObjs = Object.assign({...currentRoute.params},{...currentRoute.query} , query);
    var getParentData = (func , route , callback)=>{
        if(isLogin === null && ((route.meta||{}).login === true || (route.meta||{}).login === false)){
            isLogin = (route.meta||{}).login;
        }
        let subresult = [];
        if(route.parent && route.parent.path !== ''){
            subresult = func(func , route.parent , callback);
        }
        return callback ? callback(route , subresult) : null;
    }
    if(path !== ''){
        query = {...tool.parse_query(path) , ...query}
        path = path.split('?')[0]
        var fullPathFunc = (path , origin_path)=>{
            if(path.indexOf('../') === 0){
                let paths = path.split('../');
                for (let i = 0; i < paths.length; i++) {
                    if(paths[i] === ''){
                        origin_path = origin_path.substr(0 , origin_path.lastIndexOf('/'))
                        path = path.substr(3)
                    }else{
                        origin_path += '/';
                        break;
                    }
                }
                if(origin_path.indexOf('/') !== 0){
                    origin_path += '/';
                }
            }else if(path.indexOf('./') === 0){
                origin_path = origin_path.substr(0 , origin_path.lastIndexOf('/') + 1)
                path = path.substr(2);
            }else if(path.indexOf('/') === 0){
                origin_path = ''
            }else if(origin_path.substr(-1) !== '/'){
                origin_path += '/';
            }
            return {origin_path , fullPath:origin_path+path , basePath:origin_path ? path : ''}
        } ;
        let originName = path;
        let {origin_path , fullPath} = fullPathFunc(path ,  (currentRoute.path || currentRoute.matched[currentRoute.matched.length-1].path) + "");
        if(origin_path){
            if(originName){
                routes = router.getRoutes().filter(d=>d.name === originName)
            }
            if(routes.length === 0){
                routes = router.getRoutes().filter(d=>d.path === fullPath)
            }
            if(routes.length === 0) {
                routes = router.getRoutes().filter(d => d.path.indexOf(origin_path) === 0)
            }
            currentPath = routes.length > 0 ? routes[0].path : fullPath;
        }else{
            routes = router.getRoutes().filter(d=>d.regex.test(fullPath))
            currentPath = routes.length > 0 ? routes[0].path : fullPath;
        }
    }else{
        routes = router.getRoutes().filter(d=>d.name === currentPath)

        if(routes.length === 0){
            routes = router.getRoutes().filter(d=>d.path === currentPath)
        }
        if(routes.length === 0) {
            routes = router.getRoutes().filter(d => d.path.indexOf(currentPath) === 0)
        }
        if(routes.length > 0)currentPath = routes[0].path;
    }
    if(routes.length > 0){
        getParentData(getParentData , routes[0]);
    }
    Obj = setRouterParams(currentPath , paramObjs);
    currentPath = Obj.path;
    for (const objKey in query) {
        if(Obj.params[objKey] !== undefined){
            delete query[objKey];
        }
    }
    return { path: currentPath, query: query,params:(Obj||{}).params||{} , isLogin:isLogin};
}
export async function routeNavigateTo(to , is_replace = false){
    var $to = {...to};
    $to.replace = (isReplace || $to.replace) === true;
    if($to.replace){
        if(($to.name && $to.name === router.currentRoute.name) || ($to.path && $to.path === router.currentRoute.path)){
            let query = {};
            if($to.path)query.path = $to.path
            if($to.name)query.name = $to.name
            if($to.params)query.params = $to.params
            if($to.query)query.query = $to.query
            return await router.replace({path:'/redirect',query:query})
        }else{
            return await router.replace($to)
        }
    }else{
        return await router.push($to)
    }
}
export function routeNavigate(query , path , target , replace){
    if(!query && !path)return;
    if(path === true){
        replace = true;
        path = '';
    }
    if(!query || !(query instanceof Object)){
        if(query && typeof query === 'string' && !path){
            path = query + "";
        }
        query = {};
    }
    if(path && tool.isUrl(path)){
        return window.open(tool.variableData(path , query), !target || target === true ? '_blank' : target);
    }
    var Obj = getRouteToUrl(query || {} , path || '');
    var isLogin = Obj.isLogin;
    if(isLogin === true && !(store.state.userInfo.id > 0) && store.state.lodinShow !== true){
        store.commit("SET" , {key:'lodinShow',value:true})
        for (let i = 0; i < currentRoute.matched.length; i++) {
            let instances = currentRoute.matched[i].instances || {};
            for (const instanceKey in instances) {
                let {routeChange} = (instances[instanceKey] || {}).$parent || {routeChange:null};
                if(routeChange && typeof routeChange === 'function'){
                    routeChange(false);
                }
            }
        }
    }else if(target && target !== true){
        window.open(getRouteTargetUrl({ path: Obj.path, query: query,params:(Obj||{}).params||{} }), target);
    }else{
        replace = (replace === null || replace === undefined) ? target === true || currentRoute.path === Obj.path : replace === true;
        routeNavigateTo({ path:Obj.path, query:query,params:(Obj||{}).params||{}, replace});
    }
}
export function routeNavigateBack(number = -1 , to){
    number = Math.abs(parseInt(number));
    if(to && historys.length <= number){
        let {name , path} = to || {};
        let _to = null;
        if(path){
            _to = router.getRoutes().filter(d=>d.path === path)[0] || null
        }
        if(!_to && name){
            _to = router.getRoutes().filter(d=>d.name === name)[0] || null
        }
        if((_to || {}).path){
            let _number = delHistorys(_to);
            if(_number > -1){
                number = _number + 1;
            }else{
                let Obj = setRouterParams(_to.path ,{...(to.query||{}),...(to.params||{})});
                return router.push({...to,...Obj});
            }
        }
    }
    if(number > 0 && historys.length > number){
        historys.splice(0 , number)
        router.back( - number);
    }else{
        routeNavigateTo({path:'/',replace:true});
    }
}
export function getRouterHistorys(){
    return historys;
}
export function setRouterParams(path , query){
    query = query || {};
    var params = {} , realPath = path+'' , $_index;
    if(path.indexOf('/:') > 0){
        let ls = [...path.matchAll(/\/[a-zA-Z]{1,}(((-|_)[a-zA-Z]{1,})?)/gi)];
        let lsb = [...path.matchAll(/\/:[a-zA-Z]{1,}(((-|_)[a-zA-Z]{1,})?)/gi)];
        for (let i = 0; i < lsb.length; i++) {
            let $index = (ls.filter(d=>d.index > lsb[i].index)[0] || lsb[i+1] || {index:-1}).index;
            let regStr = lsb[i].input.substr(lsb[i].index , $index > lsb[i].index ? $index - lsb[i].index : undefined);
            if(($_index = lsb[i].input.indexOf('(',lsb[i].index)) > -1)$index = $_index;
            let paramKey = lsb[i].input.substr(lsb[i].index + 2 , $index - (lsb[i].index + 2));
            let $value = query[paramKey] !== undefined && query[paramKey] !== null && ['number','string'].indexOf(typeof query[paramKey]) > -1 ? query[paramKey] : null;
            realPath = realPath.replace(regStr , $value === null || $value === '' ? '' :'/'+$value);
            params[paramKey] = $value;
            delete query[paramKey];
        }
    }
    return {path:realPath,query , params}
}
export function getRouter(route = null){
    var routers = router.getRoutes() , name = null , path = null , query = null , params = null
    if(route === undefined || route === null){
        return routers
    }
    if(route instanceof Object){
        if(route.name)name = route.name + '';
        if(route.path)path = route.path + '';
        if(route.query)query = route.query || null;
        if(route.params)params = route.params;
    }else if(typeof route === 'string'){
        if(route.indexOf('/') > -1){
            path = route;
        }else{
            name = route;
        }
    }
    if(!name && !path && !query){
        return false;
    }
    params = {...(params || {}),...(query || {})};
    if(name && !path){
        path = (routers.filter(d=>d.name === name)[0] || {} ).path || null;
        if(path) {
            let obj = setRouterParams(path , params);
            path = obj.path;
            params = {...obj.query};
        }
    }
    if(!path) return false;
    return {path:path,query:params};
}
export default router