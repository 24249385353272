import request from "@/Utils/api";
import {Message} from "element-ui";
import $ from "jquery";
import Vue from "vue";
import $packageConfig from '../../package.json'
import SparkMD5 from 'spark-md5'
// 导入 crypto-js （如果使用的传统网页开发，script标签引入即可）
import CryptoJS from 'crypto-js'
var RS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    , KS = RS+"0123456789+/="
    , Base = {
    /**
     * base64_encode
     * @param e
     * @returns {string}
     */
    encode: function(e) { var t = "", f = 0; e = this._utf8_encode(e);  while (f < e.length) { let n = e.charCodeAt(f++); let r = e.charCodeAt(f++); let i = e.charCodeAt(f++); let s = n >> 2; let o = (n & 3) << 4 | r >> 4; let u = (r & 15) << 2 | i >> 6; let a = i & 63; if (isNaN(r)) { u = a = 64; } else if (isNaN(i)) { a = 64; } t = t + KS.charAt(s) + KS.charAt(o) + KS.charAt(u) + KS.charAt(a); } return t; },

    /**
     * base64_decode
     * @param e
     * @returns {string}
     */
    decode: function(e) { var t = "", f = 0; e = e.toString().replace(/[^A-Za-z0-9+/=]/g, ""); while (f < e.length) { let s = KS.indexOf(e.charAt(f++)); let o = KS.indexOf(e.charAt(f++)); let u = KS.indexOf(e.charAt(f++)); let a = KS.indexOf(e.charAt(f++)); let n = s << 2 | o >> 4; let r = (o & 15) << 4 | u >> 2; let i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u !== 64) { t = t + String.fromCharCode(r); } if (a !== 64) { t = t + String.fromCharCode(i); }  } t = this._utf8_decode(t);  return t; },

    _utf8_encode: function(e) { e = e.toString().replace(/rn/g, "n"); var t = ""; for (var n = 0; n < e.length; n++) { let r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128); } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128); } } return t; },

    _utf8_decode: function(e) { var t = "", n = 0; while (n < e.length) { let r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++; } else if (r > 191 && r < 224) { let c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2; } else { let c2 = e.charCodeAt(n + 1); let c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3; } } return t; },
    /**
     * 加密
     * @param str
     * @param key
     * @param garbleVal
     * @returns {string}
     */
    encrypt: function(str , key , garbleVal){ var iv = garbleVal? this.str_unqie(this.md5(garbleVal)).split('').filter(d=>isNaN(d)).map((d,i)=>i%2 === 0 ? d.toString().toUpperCase():d).join('') :this.random((arguments[2] || 2) > 4 ? arguments[2] : parseInt(Math.random() * 12 + 4)) , n = parseInt(Math.random() * 9 + 1) , _en_ = this.str_unqie(this.encode(iv + (key || '') + n + iv.split('').reverse().join(","))) , _de_ = _en_.split('').reverse().join("");return iv + n + this.strtr(this.encode(str+ '@#$%' +parseInt((new Date).getTime()/1000)) , _en_ , _de_); },
    /**
     * 解密
     * @param str
     * @param key
     * @param timeout 过期时间
     * @returns {string}
     */
    decrypt: function(str , key , timeout){
        var n = str.match(new RegExp("[0-9]+")) , l = str.indexOf(n), iv = str.substr(0 , l), r = str.substr(l , 1) , _en_ = this.str_unqie(this.encode(iv + (key || '') + r + iv.split('').reverse().join(","))) , _de_ = _en_.split('').reverse().join(""); str = this.decode(this.strtr(str.substr(l + 1, str.length - l - 1) , _de_ , _en_));var time = str.substr(str.indexOf('@#$%') + 1) || 0;if(timeout > 0 && time > 0 && ((new Date).getTime() + timeout*1000) < time*100){return null;} return str.substr(0 , str.indexOf('@#$%')); },
    /**
     * 字符替换
     * @param {Object} str
     * @param {Object} from
     * @param {Object} to
     */
    strtr : function(str,from,to){try{var fr='',i=0,j=0,lenStr=0,lenFrom=0,tmpStrictForIn=false,fromTypeStr='',toTypeStr='',istr='';var tmpFrom=[];var tmpTo=[];var ret='';var match=false;if(typeof from==='object'){tmpStrictForIn=this.ini_set('phpjs.strictForIn',false);from=this.krsort(from);this.ini_set('phpjs.strictForIn',tmpStrictForIn);for(fr in from){if(from.hasOwnProperty(fr)){tmpFrom.push(fr);tmpTo.push(from[fr])}}from=tmpFrom;to=tmpTo}lenStr=str.length;lenFrom=from.length;fromTypeStr=typeof from==='string';toTypeStr=typeof to==='string';for(i=0;i<lenStr;i++){match=false;if(fromTypeStr){istr=str.charAt(i);for(j=0;j<lenFrom;j++){if(istr==from.charAt(j)){match=true;break}}}else{for(j=0;j<lenFrom;j++){if(str.substr(i,from[j].length)==from[j]){match=true;i=(i+from[j].length)-1;break}}}if(match){ret+=toTypeStr?to.charAt(j):to[j]}else{ret+=str.charAt(i)}}return ret}catch(e){}return str},
    /**
     * 字符串去重
     * @param s
     * @returns {*}
     */
    str_unqie : function(s){var d = {};return s.split("").reduce(function(t,n){if (d[n]!==true){d[n] = true;t += n;}return t; },"") },
    /**
     * 随机字符
     * @param ln
     * @returns {string}
     */
    random : function(ln){ln=Math.max(ln||0,10);var n = "",l=RS.length;for (let i = 0;i<ln;i++){n+=RS.charAt(Math.floor(Math.random()*l));} return n },
    /**
     * 把字符串作为 URI 组件进行编码
     * @param s
     * @returns {string}
     */
    $encode:function (s) { return encodeURIComponent(s); },
    /**
     * 可对 encodeURIComponent() 函数编码的 URI 进行解码
     * @param s
     * @returns {string}
     */
    $decode:function (s) { return decodeURIComponent( s.replace(/\+/g, " ") ); },
    /**
     * unescape() 函数可对通过 escape() 编码的字符串进行解码
     * @param s
     * @returns {string}
     */
    $unescape:function (s) { return unescape(this.$encode(s)) },
    /**
     * 字符转换
     * @param s
     * @returns {undefined}
     */
    $eval:function(s){var _f = undefined;if(s === 'false' || s === 'true'){_f = s === 'true';}else if (s && typeof s === 'string'){try{_f = eval('(' + s+ ')');}catch(e){}}else{_f = s;}return _f;},
    md5_file:function(file , callback){
        return new Promise((resolve) => {
			var sparkMD52 = new SparkMD5.ArrayBuffer()
			var reader2 = new FileReader()
			reader2.readAsArrayBuffer(file)
			reader2.onload = (event) => {
				//获取文件MD5
				sparkMD52.append(event.target.result)
                reader2.md5_file = sparkMD52.end()
                resolve(reader2)
				callback && callback(reader2);
			}
        })
    },

    variableData($str , $obj){
        if(!($str && typeof $str === 'string')){
            return $str;
        }
        $obj = $obj || {};
        var reg =  /\{\{.*\}\}/g;
        let matched = null;
        while ((matched = reg.exec($str)) !== null) {
            let re = matched[0];
            let name = re.substr(2, re.length - 4);
            if($obj === true){
                $str = $str.replace(matched[0] , '$obj.'+name)
            }else{
                let str = this.getDataValue($obj, name, matched[0]);
                $str = $str.replace(matched[0] , str)
            }
        }
        return $str;
    },
    transType(s , t , d){
        if(t){
            let type = typeof s;
            if(t === 'array'){
                s = s instanceof Array ? s : (d === undefined ? [] : d);
            }else if(t === 'object'){
                s = s instanceof Object ? s : (d === undefined ? {} : d);
            }else if(t === 'string'){
                s = ['object' , 'function' ].indexOf(type) === -1 ? s.toString() : (d === undefined ? '' : d);
            }else if(t === 'number'){
                s = !isNaN(s) ? s : (d === undefined ? 0 : d);
            }else if(t === 'int'){
                s = !isNaN(s) ? parseInt(s) : (d === undefined ? 0 : d);
            }else if(t === 'float'){
                s = !isNaN(s) ? parseFloat(s) : (d === undefined ? 0 : d);
            }else if(t === 'bool' || t === 'boolean'){
                s = s instanceof Boolean ? s : (d === undefined ? false : d);
            }
        }
        return s;
    },
    getDataValue($obj , $keys ,$default , $valueType){
        if($keys === undefined) return $default;
        $obj = $obj || [];
        $keys = (typeof $keys === 'object' ? $keys : $keys.replace(/(\[|\])/g,'.').split('.')).filter(d=>{return d.trim() !== ''});
        let _$obj;
        try{
            _$obj = $obj instanceof Array ? [...$obj] : (_$obj instanceof Object ? {...$obj} : $obj);
            for (const $lKey in $keys) {
                if(_$obj[$keys[$lKey]] !== undefined){
                    _$obj = _$obj[$keys[$lKey]];
                }else if(($valueType === 'array' || !$valueType) && _$obj instanceof Array){
                    let arr = [];
                    for (let j = 0; j < _$obj.length; j++) {
                        if(_$obj[j][$keys[$lKey]] !== undefined){
                            if(_$obj[j][$keys[$lKey]] instanceof Array){
                                arr = arr.concat(_$obj[j][$keys[$lKey]])
                            }else{
                                arr.push(_$obj[j][$keys[$lKey]]);
                            }
                        }
                    }
                    _$obj = [...arr];
                }else{
                    _$obj = $default;
                    break;
                }
            }
        }catch (e) {
            _$obj = $default;
        }
        return this.transType(_$obj , $valueType , $default);
    },
    setDataValue(obj , data , split){
        data = data || {};
        for (const objKey in obj) {
            if(data[objKey] !== undefined || split === false){
                data[objKey] = obj[objKey];
            }else{
                var keys = objKey.replace(/(\[|\])/g,'.').split('.').filter(d=>{return d.trim() !== ''})
                    ,evalStr = 'data'
                    ,$evals = []
                    , _isArray = objKey.substr(0 , 1) === '[';
                for (let i = 0; i < keys.length; i++) {
                    let isArray = ('data' + (_isArray ? '' : '.')+objKey).indexOf((evalStr + '[' + keys[i] + ']')) > -1
                    if(evalStr !== 'data'){
                        $evals.push(evalStr+" = "+evalStr + (isArray ? ' || [];' : ' || {};'));
                    }
                    if(isArray){
                        evalStr = evalStr+'['+keys[i]+']';
                    }else{
                        evalStr = evalStr+'.'+keys[i];
                    }
                }
                $evals.push(evalStr+" = obj[objKey];");
                evalStr = $evals.join(" ");
                if(evalStr){
                    try{
                        eval(evalStr);
                        // console.log("setDataValue ---- success" , evalStr);
                    }catch (e) {
                        console.error(e , evalStr , 'data' + (_isArray ? '' : '.')+objKey);
                        break;
                    }
                }
            }
        }
        return data;
    },
    ObjectAssign:function(obj , ...vars){
        let split = '-';
        let type = 1;
        let filter = function($v){return !($v === undefined || $v === null);};
        let ObjectAssign = (obj , obj1 , type , split , filter)=>{
            try{
                if (obj1 instanceof Array) {
                    obj = (obj && !(obj instanceof Array)) || !obj? [] : [...obj];
                    obj1.map((item,index) => {
                        if(item instanceof Function){
                            obj[index] = item;
                        }else if(obj[index] && typeof obj[index] === 'object' && typeof item === 'object'){
                            obj[index] = ObjectAssign(obj[index],item , type , split , filter);
                        }else{
                            obj[index] = typeof item === 'object' ? ObjectAssign({},item , type , split , filter) : item;
                        }
                        return item;
                    })
                }else{
                    for (const obj1Key in obj1) {
                        let objKey = type === 1 ? this.underlineToHump(obj1Key , split) : ( type === 0 ? this.humpToUnderline(obj1Key , split) : obj1Key);
                        if(obj[objKey] instanceof Function){
                            obj[objKey] = obj1[obj1Key];
                        }else if(obj[objKey] && typeof obj[objKey] === 'object' && typeof obj1[obj1Key] === 'object'){
                            obj[objKey] = ObjectAssign(obj[objKey] , obj1[obj1Key]  , type , split , filter);
                        }else if(filter(obj1[obj1Key])){
                            obj[objKey] = obj1[obj1Key];
                        }
                    }
                }

            }catch (e) {}
            return obj;
        }
        let $obj = typeof obj === 'object' ? obj : {};
        let index = 0;
        for (let i = 0; i < vars.length; i++) {
            let obj1 = vars[i];
            let _t = typeof obj1;
            if(i < 3 && _t === 'function'){
                filter = vars[i];
            }else if(i < 3 && _t === 'string'){
                split = vars[i];
            }else if(i < 3 && _t === 'number'){
                type = vars[i];
            }else if(_t === 'object'){
                if(index === 0){
                    $obj = ObjectAssign({} , $obj  , type , split , filter);
                }
                $obj = ObjectAssign($obj , obj1  , type , split , filter);
                index ++ ;
            }
        }
        if(index === 0){
            $obj = ObjectAssign({} , $obj  , type , split , filter);
        }
        return $obj;
    },
    obj_diff:function (arr1 , arr2) {
        let type = typeof arr1;
        if(type === 'object' && type === typeof arr2){
            let isArry = arr1 instanceof Array && arr2 instanceof Array;
            var def = isArry ? [] : {};
            var diff = isArry ? [] : {};
            let keys = [];
            for (const arrKey in arr1) {
                keys.push(arrKey);
                if(this.obj_equal(arr1[arrKey] , arr2[arrKey]) !== false){
                    if(isArry){
                        diff.push(arr1[arrKey]);
                    }else{
                        diff[arrKey] = arr1[arrKey];
                    }
                }
            }
            for (const arrKey in arr2) {
                if(keys.indexOf(arrKey) === -1){
                    if(isArry){
                        diff.push(arr2[arrKey]);
                    }else{
                        diff[arrKey] = arr2[arrKey];
                    }
                }
            }
            return diff === def ? false : diff;
        }else{
            return this.obj_equal(arr1 , arr2);
        }
    },
    obj_equal:function(arr1 , arr2 , isGet = false){
        var ie = (func , arr1 , arr2 , priex = '')=>{
            var diffValue = null,insertValue=null,delValue=null,changeValue=null,keys=null,equal=false
                ,t = (arr1 = (arr1||'') instanceof Date?arr1.getTime():arr1) === null ? null : typeof arr1
                , t1 = (arr2 = (arr2||'') instanceof Date?arr2.getTime():arr2) === null ? null : typeof arr2;
            try{equal = (t === 'object' ?JSON.stringify(arr1) : arr1+'') === (t1 === 'object' ?JSON.stringify(arr2) : arr2+'');}catch (e) {}
            if(t === 'object' && t1 === 'object' && equal === false){
                if(arr1.constructor === Array && arr2.constructor === Array && arr1.filter(d=>['number','string','boolean'].indexOf(typeof d)>-1).length === arr1.length && arr2.filter(d=>['number','string','boolean'].indexOf(typeof d)>-1).length === arr2.length){arr1 = arr1.sort((a,b)=>a-b);arr2 = arr2.sort((a,b)=>a-b); }
                var $def = ()=>{return arr1.constructor === Array || arr2.constructor === Array ? []:{}};
                keys = Object.keys({...arr1,...arr2});
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    let r = func(func , arr1[key] , arr2[key] , priex +(!isNaN(key)?'['+key+']':'["'+key+'"]'));
                    if(r.equal === false && isGet === true) {
                        if (r.diffValue) (diffValue=diffValue||$def())[key] = r.diffValue;
                        else (diffValue=diffValue||$def())[key] = arr2[key];
                        if (r.insertValue) (insertValue=insertValue||$def())[key] = r.insertValue;
                        else if (arr1[key] === undefined) (insertValue=insertValue||$def())[key] = arr2[key];
                        if (r.delValue) (delValue=delValue||$def())[key] = r.delValue;
                        else if (arr2[key] === undefined) (delValue=delValue||$def())[key] = arr1[key];
                        if(r.changeValue)(changeValue=changeValue||$def())[key] = r.changeValue;
                        else if(arr1[key] !== undefined && arr2[key] !== undefined)(changeValue=changeValue||$def())[key] = arr2[key];
                    }
                }
            }else if(equal === false && t1 === 'object'){
                diffValue = arr2;
            }
            return {equal,diffValue,insertValue,changeValue,delValue};};
        let r = ie(ie , arr1 , arr2); if(isGet === true){return r;}return r.equal === true;},
    obj_filter:function (arr1 , filter) {
        if(typeof arr1 === 'object'){
            let isArry = arr1 instanceof Array;
            var obj = isArry ? [] : {};
            filter = filter || function(v){ return v !== null && v !== '' && v !== undefined;};
            for (const arrKey in arr1) {
                if(filter(arr1[arrKey])){
                    if(isArry){
                        obj.push(arr1[arrKey]);
                    }else{
                        obj[arrKey] = arr1[arrKey];
                    }
                }
            }
            return obj;
        }
    },
    stringToObject(str , split , split2 , _default){
        if(typeof str === 'object')return str;
        if(str && typeof str !== 'function'){
            split = split !== undefined && split !== null ? split : ',';
            let ss = str.toString().split(split);
            if(split2 !== undefined && split2 !== null){
                let Obj = {};
                for (const ssKey in ss) {
                    let ss1 = ss[ssKey].split(split2);
                    Obj[ss1[0].trim()] = ss1[1] === undefined ? null : (ss1[1] === 'true' ? true : (ss1[1] === 'false' ? false : ss1[1])) ;
                }
                return Obj;
            }
            return ss;
        }
        return _default === undefined ? {} : _default;
    },
    obj_diff_key(arr1 , keys , is_upper){
        let isArry = arr1 instanceof Array;
        let arr = isArry ? arr1 : [arr1];
        let arr2 = [];
        keys = allObjKeys(keys , is_upper);
        for (let i = 0; i < arr.length; i++) {
            if(typeof arr[i] === 'object'){
                let obj = {};
                for (const key in arr[i]) {
                    if(keys.indexOf(key) === -1){
                        obj[key] = arr[i][key];
                    }
                }
                arr2.push(obj);
            }
        }
        return isArry ? arr2 : (arr2[0] || {})
    },
    obj_intersect_key(arr1 , keys , is_upper , prefix){
        prefix = prefix || '';
        keys = keys || [];
        if(keys.indexOf('*') > -1 || (prefix && keys.indexOf(prefix) > -1))return arr1;
        var $arr1 , _this = this;
        if(arr1 && typeof arr1 === 'object'){
            if(arr1 instanceof Array){
                arr1.map((d,index)=>{
                    let ret = _this.obj_intersect_key(d  , keys , is_upper, prefix+'['+index+']')
                    if(ret !== undefined){
                        $arr1 = $arr1 || [];
                        $arr1.push(ret);
                    }
                    return ret
                });
            }else{
                prefix = prefix ? prefix +'.' : prefix;
                for (const objKey in arr1) {
                    let key = is_upper ? _this.underlineToHump(objKey) : objKey;
                    let ret = _this.obj_intersect_key(arr1[objKey] , keys , is_upper , prefix+objKey);
                    if(ret !== undefined){
                        $arr1 = $arr1 || {};
                        $arr1[key] = ret;
                    }else{
                        let ret = _this.obj_intersect_key(arr1[objKey] , keys , is_upper , prefix+key);
                        if(ret !== undefined){
                            $arr1 = $arr1 || {};
                            $arr1[key] = ret;
                        }
                    }
                }
            }
        }
        return $arr1;
    },
    formatHumpLineTransfer(data, type = 1 , split = '-' , filter) {
        let hump = ''
        // 转换对象中的每一个键值为驼峰的递归
        let formatTransferKey = (data) => {
            if (data instanceof Array) {
                data.forEach(item => formatTransferKey(item))
            } else if (data instanceof Object) {
                let _data = {};
                for (let key in data) {
                    hump = type === 1 ? this.underlineToHump(key , split) : this.humpToUnderline(key , split)
                    if (hump === ''){
                        continue;
                    }
                    if(data[hump] instanceof Function){
                        _data[hump] = data[key]
                    }else if (data[hump] instanceof Object) {
                        _data[hump] = formatTransferKey(data[hump])
                    }else{
                        _data[hump] = data[key]
                    }
                    if(filter && filter(_data[hump]) === false){
                        delete _data[hump];
                    }
                }
                return _data;
            } else if (typeof data === 'string') {
                data = type === 1 ? this.underlineToHump(data , split) : this.humpToUnderline(data , split)
            }
            return data;
        }

        return formatTransferKey(data);
    },
    humpToUnderline(str,split){
        return str.replace(/([A-Z])/g, (split || '_')+'$1').toLowerCase()
    },
    underlineToHump(str , split){
        if (!str) return ''
        return str.replace(new RegExp((split || '_')+'(\\w)','g'), (_, letter) => letter.toUpperCase())
    },
    MutationObserver(element , callback){
        callback = callback || function(e){}
        try{
            $(window).resize(callback)
            var mutationObserver = new MutationObserver(function(mutations) {
                callback({type:"MutationObserver"} , mutations);
            });
            /**Element**/
            mutationObserver.observe(element, {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
                attributeOldValue: true,
                characterDataOldValue: true
            });
        }catch (e) {
            $(element).bind("DOMNodeInserted DOMNodeRemoved change",callback)
        }
    },
}
function isUrl(str) {
     var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i');
     return v.test(str);
}
function allObjKeys(keys , is_upper){
    if(is_upper === true || is_upper === undefined || (is_upper && is_upper instanceof String)){
        keys = keys || [];
        let split = is_upper && is_upper instanceof String ? is_upper : '-';
        let l = keys.length;
        let regx = new RegExp(split+'(\\w)' , 'g')
        for (let i = 0; i < l; i++) {
            let s;
            if(keys[i].indexOf(split) > 0){
                s = keys[i].replace(regx, (_, letter) => {
                    return letter.toUpperCase()
                })
            }else{
                s = keys[i].replace(/([A-Z])/g, split+'$1').toLowerCase()
            }
            if(s !== keys[i] && keys.indexOf(s) === -1){
                keys.push(s);
            }
        }
    }
    return keys;
}
var fileDowns = {};
export default {
    ...Base,
    isUrl:isUrl,
    base64_encode:Base.encode,
    base64_decode:Base.decode,
    element_callback(selectorAll , number , callback , filterCallback){
        callback = typeof callback === 'function' ? callback : null;
        if(!callback || ['[object NodeList]','[object Array]'].indexOf(Object.prototype.toString.call(selectorAll)) === -1) return ;
        number = parseInt(number || 0);
        var $index = null , $selector = null , maxOffset = -1;
        if(number === -3){
            $index = selectorAll.length - 1;
            $selector = selectorAll[$index];
        }else {
            for (let i = 0; i < selectorAll.length; i++) {
                if(number === -2 || number === -1){// -1 最高 -2 最低
                    let val = null;
                    if(filterCallback && filterCallback instanceof Function){
                        val = filterCallback(selectorAll[i]);
                    }else if(filterCallback && filterCallback instanceof String){
                        val = selectorAll[i][filterCallback] === undefined ? selectorAll[i].offsetHeight : selectorAll[i][filterCallback];
                    }else{
                        val = selectorAll[i].offsetHeight
                    }
                    if((number === -1 && val > maxOffset) || (number === -2 && val < (maxOffset === -1 ? val + 1 : maxOffset))){
                        $selector = selectorAll[i];
                        maxOffset = val;
                        $index = i;
                    }
                }else if(number === -3){
                    $selector = selectorAll[i];
                    $index = i;
                }else if(i === number){
                    $selector = selectorAll[i];
                    $index = i;
                    break;
                }
            }
        }
        if($selector){
            for (let i = 0; i < selectorAll.length; i++) {
                callback(true , i , selectorAll[i], $index , $selector);
            }
        }else{
            callback(false);
        }
    },
    locationInit(){
        let locatPath = location.pathname.substr(0,location.pathname.lastIndexOf('/'));
        location.static_base_url = location.href.indexOf('#') > -1 ? location.origin + locatPath + '/' : process.env.VUE_APP_BASE_URL;
// 设置统一的url
        location.currentURL = location.origin + locatPath.substr(0,locatPath.lastIndexOf('/'));
        if((process.env.VUE_APP_BASE_URL || '/').indexOf('://') === -1){
            if(process.env.NODE_ENV === 'production'){
                location.baseURL = location.currentURL + "/";
            }else{
                // location.baseURL ='http://kenkeding.test.locathost.cn/index.php/';
                // location.baseURL ='http://www.demo.kencoding.com.cn/';
                // location.baseURL ='http://kencoding.four.xinzhidi.cn/index.php/';
                // location.baseURL ='http://www.kencoding.com.cn/';
                location.baseURL ='http://test.kencoding.com.cn/';

            }
        }else{
            location.baseURL = process.env.VUE_APP_BASE_URL;
        }
        const pathname = location.pathname && location.pathname !== '/' ? location.pathname.substr(1 , (location.pathname.lastIndexOf('/') <= 0 ? location.pathname.length : location.pathname.lastIndexOf('/') ) - 1) : '';
        const $module = pathname.split('/')[pathname.split('/').length - 1] || process.env.MODULE;
        location.$module = ['admin' , 'web'].indexOf($module) > -1 ? $module : (process.env.MODULE || 'web')
        location.authorization = function(){
            const user_id = localStorage.getItem("user_id")
            const user_token = localStorage.getItem("user_token")
            if(user_id && user_token) {
                var $str = Base.encrypt('{"id":'+user_id+',"token":"'+user_token.substr(0,8)+'"}');
                // console.log("authorization" , $str ,  Base.decrypt($str));
                return Base.$decode($str)
            }else{
                return null
            }
        }();
        Vue.prototype.$location = location
        $packageConfig.version = '2.0.0';
        location.$packageConfig = $packageConfig;
        Vue.prototype.$packageConfig = $packageConfig
        return location;
    },
    parse_query(params){
        if(params.indexOf('&') === -1 && params.indexOf('=') === -1)return {};
        let index = params.indexOf('?');
        return Base.stringToObject(index > -1 ? params.substr(index + 1 , params.length - index - 1) : params , '&' , '=');
    },
    build_query(query) {
        return Object.entries(query)
            .reduce((result, entry) => {
                result.push(entry.join('='))
                return result
            }, []).join('&')
    },
    arr_diff(arr1 , arr2){
        return arr1.concat(arr2).filter(item => !arr2.includes(item))
    },
    indexOf(arr , str){
        if(arr instanceof Array){
            return arr.map(d=>d+"").indexOf(str+"");
        }
        return arr.indexOf(str+"");
    },
    treeListEach:function treeListEach(_data , callback , sub ='children'){
        sub = sub || 'children';
        var t = function(f , _data , c , sub , parent = [] , level = 1){
            if((_data||'').constructor !== Array)return [];
            var list = [] , data = _data.concat([]) , ni = 0;
            for (let i = 0; i < data.length; i++) {
                data[i][sub] = data[i][sub] || [];
                let subList = data[i][sub].length > 0 ? f(f,data[i][sub] , c , sub , parent.concat([{...data[i] , _index:i}]) , level + 1) : [];
                if(c) {
                    var d = c({item:data[i] , key:i , subList:subList , parent:parent , level:level});
                    if(d === false){
                        _data.splice(ni , 1);
                        continue;
                    }
                    if(d instanceof Object){
                        data[i] = d;
                    }
                    if(d instanceof Array){
                        subList = d;
                    }
                }
                _data[ni] = data[i];
                ni ++;
                list.push(data[i])
                if(subList.length > 0)list = list.concat(subList);
            }
            return list;
        }
        return t(t , _data , callback , sub);
    },
    htmlspecialchars:function htmlspecialchars(str){
        var s = "";
        if (typeof str !== 'string' || str.length === 0) return str;
        for   (var i=0; i<str.length; i++){
            switch (str.substr(i,1))
            {
                case "<": s += "&lt;"; break;
                case ">": s += "&gt;"; break;
                case "&": s += "&amp;"; break;
                case " ":
                    if(str.substr(i + 1, 1) === " "){
                        s += " &nbsp;";
                        i++;
                    } else s += " ";
                    break;
                case "\"": s += "&quot;"; break;
                case "\n": s += "<br>"; break;
                default: s += str.substr(i,1); break;
            }
        }
        return s;
    },
    htmlspecialchars_decode:function htmlspecialchars_decode(str){
        str = str.replace(/&amp;/g, '&');
        str = str.replace(/&lt;/g, '<');
        str = str.replace(/&gt;/g, '>');
        str = str.replace(/&quot;/g, "\"");
        str = str.replace(/&#039;/g, "'");
        return str;
    },
    fileResponse(url , where , filename , config){
        console.log(url,"url")
        console.log(where,"where")
        console.log(filename,"filename")
        console.log(config,"config")
        let isDown = where === true || filename === true || config === true || (arguments[4] === undefined ? true : arguments[4]) === true;
        where = where || {};
        config = config || {};
        return new Promise((resolve , reject)=>{
            if(url && isNaN(url) === false){
                where.file_id = url + '';
                url = '/xapi/home/download';
            }else if(url.indexOf('://') > 0){
                where.file = url + '';
                url = '/xapi/home/download';
            }else if(!url && where.file){
                url = '/xapi/home/download';
            }
            if(!url) {
                return reject(new Error("网址错误"));
            }
            let key = url+'';
            console.log(key,"key")
            console.log(fileDowns,'fileDowns')
            console.log(fileDowns[key],'fileDowns')
            if(fileDowns[key]){
                return Message.warning("正在下载中...");
            }
            fileDowns[key] = true;
            if(isDown){
                where.t = (new Date()).getTime()
            }
            config = Object.assign({
                message: true,
            } ,config , {
                url:url,
                params:where,
                method: config.method || 'get',
                headers: Object.assign({
					"Content-Type":"application/json;charset=utf-8"
				},config.headers || {}),
                responseType:'blob',
                cache:false,
                withCredentials: false,
                timeout:false,
            });
			var blobValidate = async function(data) {
			  try {
                // console.log(data.text(),"data.text()")
				const text = await data.text();
                // console.log(text,"text")
				// var json = JSON.parse(text);
                // console.log(json,"jsonjson")
				return json;
			  } catch (error) {
				return false;
			  }
			}
            request(config).then(async (res)=>{
                if(res.data.size === 0) {
                    fileDowns[key] = false;
                    Message.error("文件不存在！");
                    return reject(new Error("文件不存在！"));
                }
                // console.log(res.data,"resssssssssssss")
				var json = await blobValidate(res.data);

                // console.log(json,"json~~~~~~~~~~~")

				if(json !== false){
                    // console.log(json,"json-------")
					fileDowns[key] = false;
					// Message.error(json.msg || error.message || '下载错误！');
					Message.error(json.msg ||  '下载错误！');
					// Message.error('下载错误！');
					return reject(json);
				}
                if(!filename || typeof filename !== 'string'){
                    filename = '';
                    if(res.headers['file-base64'] !== undefined){
                        filename = this.base64_decode(res.headers['file-base64']);
                    }
                    if(!filename && res.headers['content-disposition'] !== undefined){
                        //Content-Disposition: attachment; filename="WeChat_20211022170855.mp4"
                        let params = this.stringToObject(res.headers['content-disposition'],';','=');
                        filename = params.filename.substr(1,params.filename.length - 2);
                    }
                    if(!filename){
                        if((filename === true || !filename) && url && url instanceof String){
                            filename = url.split('/')[url.split('/').length - 1]
                            filename = filename.indexOf('?') > -1 ? filename.substr(0 , filename.indexOf('?')) : filename;
                        }
                    }
                    if(!filename) {
                        return reject(new Error("请设置文件名"));
                    }
                }
                res.filename = filename;
                fileDowns[key] = false;
                if(isDown){
					var blob = res.data;
                    // fileName 一定是要带后缀名的，而且要和blob匹配，否则下载文件打不开
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE系列兼容,兼容IE10+，Edge12-18
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        const downloadElement = document.createElement('a');
                        window.URL = window.URL || window.webkitURL
                        const href = window.URL.createObjectURL(blob);
                        downloadElement.href = href;
                        downloadElement.download = filename;
                        document.body.appendChild(downloadElement);
                        downloadElement.click();
                        document.body.removeChild(downloadElement);
                        window.URL.revokeObjectURL(href);
                    }
                }
				resolve(res);
            }).catch((error)=>{
                fileDowns[key] = false;
                reject(error);
            });
        });
    },
    timeConV(time , currentTime){
        currentTime = (currentTime || (new Date).getTime())/1000
        if(time.toString().length > 13){
            time = time / 1000;
        }
        let text = '';
        let t = currentTime - time;
        if(t < 60){
            text = '刚刚';
        }else if(t >= 60 && t < 3600){
            text = parseInt(t/60)+"分钟前";
        }else if(t >= 3600 && t < 3600 * 24){
            text = parseInt(t/3600)+"小时前";
        }else if(t >= 3600 * 24 && t < 3600 * 24 * 7){
            text = parseInt(t/(3600 * 24))+"天前";
        }
        return text;
    },
}