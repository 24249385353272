<template>
  <div class="problemList">
    <!-- 轮播图 -->
    <!-- <com-carousel  :banners="matchCarousel" :fullWidth="true" trigger="click" height="550px"></com-carousel> -->
    <div class="content">
      <Breadcrumb></Breadcrumb>
      
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
                ref="searchFilter"
                :searchName="'搜索 比赛·考试·作业'"
                :keywordOptions="{placeholder:'请输入比赛·考试·作业名称',prop:'keyword',autoName:'course',autocomplete:true}"
                :searchOptions="[
                  { name:'el-form-item' ,
                    options:{label:'难度' , prop:'diff_id', type:'radio-group'  , data:{ name:'question_other', keys:'diff_id' } , defaultValue :'全部'}
                  },
                  { name:'el-form-item' ,
                    options:{label:'来源' , prop:'type', type:'radio-group' , data:{ name:'question_other', keys:'type' } , defaultValue :'全部' }
                  },
                  // { name:'el-form-item' ,
                  //   options:{label:'类型' , prop:'match_id', type:'radio-group' , data:{ name:'question_other', keys:'match' } , defaultValue :'全部' }
                  // },
                  // { name:'el-form-item' ,
                  //   options:{label:'类别' , prop:'match_type', type:'radio-group' , data:{ name:'question_other', keys:'match_type' } , defaultValue :'全部' }
                  // },
              ]"
                v-model="where"
                :total="total"
                @search="currentChange(1)"
            ></search-filter>
            <div class="operate">
            <el-button
              type="primary"
              size="mini"
              style="height: 30px"

              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMymatch"
            >
              我管理的比赛
            </el-button>
            
            <el-button
              type="primary"
              size="mini"
              style="height: 30px"

              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMycourse"
            >
              我管理的考试
            </el-button>
            <el-button
              type="primary"
              size="mini"
              style="height: 30px"

              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMyexam"
            >
              我管理的作业
            </el-button>
            <el-button
              type="primary"
              size="mini"
              style="height: 30px"

              @click="mine" v-if="userInfo.type=== 1"
            >
            我参加的
            </el-button>
            <el-button
              type="primary"
              size="mini"
              style="height: 30px"

              @click="establish" v-if="userInfo.type=== 2"
            >
            创建
            </el-button>
          </div>

          </el-card>
          <div class="operate">
            <!-- <el-button
              type="primary"
              size="mini"
              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMymatch"
            >
              我管理的比赛
            </el-button>
            
            <el-button
              type="primary"
              size="mini"
              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMycourse"
            >
              我管理的考试
            </el-button>
            <el-button
              type="primary"
              size="mini"
              :plain="params.match_type === 1"
              v-if="userInfo.type === 2"
              @click="showMyexam"
            >
              我管理的作业
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="establish" v-if="userInfo.type=== 2"
            >
            创建
            </el-button> -->

            <!-- <div class="button-primary" :style="{ padding:'4px 20px'}" @click="mine" v-if="userInfo.type=== 1">
              我的
            </div>
            <div class="button-primary" :style="{ padding:'4px 20px'}" @click="establish" v-if="userInfo.type=== 2">
              创建
            </div> -->
            <!-- <div class="button-primary" :style="{ padding:'4px 20px'}" @click="establish" v-if="userInfo.type=== 2||userInfo.type=== 1">
              创建
            </div> -->
          </div>
          <div class="tableDatas">

            <Matchlist :tableData="tableData" :params="params" :loading="loading" ref="Matchlist"></Matchlist>
            <div class="pagination">
              <el-pagination background layout="total,sizes,prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="currentChange" :page-size="pageSize" :current-page="page">
              </el-pagination>
            </div>
          </div>

        </div>
        <!-- <div class="content-right">
          <Advertising class="margin"></Advertising>
          <HotRecommend></HotRecommend>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { carousel } from "@/api/common.js"

import { matchList } from "@/api/match.js"
// import Advertising from "@/components/Advertising/Advertising.vue"
// import HotRecommend from "@/views/api/com/hotRecommend";
import Matchlist from "./components/Matchlist.vue"
// import ComCarousel from "@/components/com/comCarousel";
import SearchFilter from "@/components/com/SearchFilter";
export default {
  components: {
    SearchFilter,
    // ComCarousel,
    // Advertising,
    // HotRecommend,
    Matchlist,
  },
  data () {
    return {
      loading: false,
      page: 1,
      pageSize: 100,
      total: 0,
      tableData: [],
      params: {},
      matchCarousel: [],
      match_type:0,//2为我参加的
    }
  },
  created(){
// console.log(this.$route.query,"this.$route.query.params");
  },
  methods: {
    routeUpdate(){
      // 轮播
      // this.getIndexCarousel()
      // console.log(this.$route.query,"this.$route.query");
      // console.log(this.$route.query.params,"this.$route.query.params");
      this.where = {...this.$route.query,...this.$route.query.params};
      // console.log(this.where,"wwwwwwwwww");
    },

    // 我的
    mine(){
      this.match_type=2
      let data={
        ...this.where,
        match_type:this.match_type,
        page: this.page, 
        page_size: this.pageSize 
      }
      matchList(data)
        .then(({ data }) => {
          console.log("获取列表列表1111");
          this.tableData = data.data
          this.total = data.total
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },

    //   点击创建
    establish () {
      // if(this.userInfo.type+'' === '2'){
      //   this.$handleRoute('teachingQuestListCreate')
      // }else{
        this.$refs.Matchlist.dialogVisible = true
      // }
    },
    // getIndexCarousel () {
    //   let storageKey = "bs"
    //   let data = this.$storage.get(storageKey)
    //   if (data) {
    //     this.matchCarousel = data.list
    //   }
    //   carousel({ type: 6 })
    //     .then(({ data }) => {
    //       this.matchCarousel = data.list
    //       this.$storage.set(storageKey, data)

    //       // console.log(data, "比赛轮播")
    //     })
    //     .catch((err) => {
    //       // console.log(err, "比赛轮播失败 ")
    //     })
    // },

    getMatchList (params) {
      console.log(params,"paramsparams");
      console.log(this.where,"this.where");

      this.loading = true
      let data={
        match_type:this.match_type,
        ...this.where,
        ...params,
        page: this.page, 
        page_size: this.pageSize 
      }
    console.log(data,"dataaaaa");
    if(data.type){
      // matchList({...this.where, ...(params||{}), page: this.page, page_size: this.pageSize })
      matchList(data)
        .then(({ data }) => {
          console.log("获取列表列表1");
          this.tableData = data.data
          this.total = data.total
          // console.log(data, "比赛成功")
          this.loading = false
        })
        .catch((err) => {
          // console.log(err, "比赛失败")
          this.loading = false
        })
    }else{
      this.match_type=0
       matchList({...this.where, ...(params||{}), page: this.page, page_size: this.pageSize,match_type:this.match_type })
        .then(({ data }) => {
          console.log("获取列表列表2");
          this.tableData = data.data
          this.total = data.total
          // console.log(data, "比赛成功")
          this.loading = false
        })
        .catch((err) => {
          // console.log(err, "比赛失败")
          this.loading = false
        })
    }
  
    }, 
    // 我的比赛
     showMymatch(){
      this.$handleRoute('/match/myMatchList')
    },
    // 我的作业
     showMyexam(){
      this.$handleRoute('/match/myExamList')
    },
    // 我的考试
    showMycourse(){
      this.$handleRoute('/match/myCourseList')
    },
    // 分页
    currentChange (e) {
      this.page = e
      this.getMatchList()
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize=val
      this.getMatchList()

      },
  },
};
</script>

<style lang="less" scoped>
// ::v-deep {
// }
::v-deep {
  .el-card__body {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .classify {
      flex: 1;
    }
  }
}
.operate {
  // margin: 2.2% 0;
  display: flex;
  justify-content: flex-end;
  .new_problemList {
    margin-left: 1rem;
  }
}
.pagination {
  // margin: 2rem 0;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;

  .content-left {
    // width: 70%;
    width: 100%;
    .tableDatas {
      margin-top: 20px;
      background-color: #fff;
    }
  }
  .content-right {
    width: 27%;
    .margin {
      margin-bottom: 10%;
    }
  }
}
.banner {
  height: 100%;
  .el-image {
    display: inline-block;
    height: 100%;
  }
}
</style>